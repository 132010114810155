:root {
  --shade1: #777;
  --shade2: #999;
  --shade3: #eee;
}

@media (prefers-color-scheme: dark) {
  :root {
    color: white;
    background: #1a1a1a;
    --shade1: #777;
    --shade2: #444;
    --shade3: #222;
  }

  select {
    background: var(--shade2);
    border: none;
    color: white;
  }
}

body {
  font-family: sans-serif;
  max-width: 80ch;
  margin: 2em auto;
}

section {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 1em;
  margin-bottom: 1em;
}
section h2 {
  margin-top: 0;
}

td select {
  width: 100%
}

button {
  padding: 0.7em 1em;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: bold;
  font-family: inherit;
  background: var(--shade2);
}
button:active {
  background: var(--shade1);
}
button:hover {
  cursor: pointer;
}

.actions {
  text-align: right;
  margin-top: 1em;
}


.Info {
  display: flex;
  flex-direction: row;
}
.Info * {
  flex-grow: 1;
  margin-right: 1em;
}
.Info :last-child {
  margin-right: 0;
}

.Info table {
  width: 100%;
}
.Info td:first-child {
  font-weight: bold;
  margin-right: 0.5ch;
}
.Info td:first-child:after {
  content: ":";
}

.Info button {
  all: unset;
  color: var(--shade1);
  text-decoration: underline;
}
.Info button:hover {
  cursor: pointer;
}

.FitRecommendations table {
  width: 100%;
  border-collapse: collapse;
}
.FitRecommendations table td, table th {
  border: 2px solid var(--shade2);
}

.FitRecommendations .ProductFilter {
  margin-bottom: 0.5em;
}

.RecommendationTable > tbody > tr > td:nth-of-type(1) {
  text-align: center;
}
.RecommendationTable > tbody > tr > td:nth-of-type(4) {
  background: var(--shade3);
}

.Recommendation .RecommendationFilter {
  white-space: pre-wrap;
}
